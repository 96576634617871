export default {
  styleJson: [
    {
      "featureType": "water",
      "elementType": "all",
      "stylers": {
        "color": "#044161"
      }
    },
    {
      "featureType": "land",
      "elementType": "all",
      "stylers": {
        "color": "#004981"
      }
    },
    {
      "featureType": "boundary",
      "elementType": "geometry",
      "stylers": {
        "color": "#064f85"
      }
    },
    {
      "featureType": "railway",
      "elementType": "all",
      "stylers": {
        "visibility": "off"
      }
    },
    {
      "featureType": "highway",
      "elementType": "geometry",
      "stylers": {
        "color": "#004981"
      }
    },
    {
      "featureType": "highway",
      "elementType": "geometry.fill",
      "stylers": {
        "color": "#005b96",
        "lightness": 1
      }
    },
    {
      "featureType": "highway",
      "elementType": "labels",
      "stylers": {
        "visibility": "off"
      }
    },
    {
      "featureType": "arterial",
      "elementType": "geometry",
      "stylers": {
        "color": "#004981"
      }
    },
    {
      "featureType": "arterial",
      "elementType": "geometry.fill",
      "stylers": {
        "color": "#00508b"
      }
    },
    {
      "featureType": "poi",
      "elementType": "all",
      "stylers": {
        "visibility": "off"
      }
    },
    {
      "featureType": "green",
      "elementType": "all",
      "stylers": {
        "color": "#056197",
        "visibility": "off"
      }
    },
    {
      "featureType": "subway",
      "elementType": "all",
      "stylers": {
        "visibility": "off"
      }
    },
    {
      "featureType": "manmade",
      "elementType": "all",
      "stylers": {
        "visibility": "off"
      }
    },
    {
      "featureType": "local",
      "elementType": "all",
      "stylers": {
        "visibility": "on"
      }
    },
    {
      "featureType": "arterial",
      "elementType": "labels",
      "stylers": {
        "visibility": "off"
      }
    },
    {
      "featureType": "boundary",
      "elementType": "geometry.fill",
      "stylers": {
        "color": "#029fd4"
      }
    },
    {
      "featureType": "building",
      "elementType": "all",
      "stylers": {
        "color": "#1a5787"
      }
    },
    {
      "featureType": "label",
      "elementType": "labels.text",
      "stylers": {
        "fontsize": 40
      }
    },
    {
      'featureType': 'label',
      'elementType': 'labels.text.fill',
      'stylers': {
        'color': '#33AAAAAA',
        "weight": 2
      }
    },
    {
      'featureType': 'label',
      'elementType': 'labels.text.stroke',
      'stylers': {
        'color': '#33000000'
      }
    },
    {
      "featureType": "city",
      "elementType": "labels.icon",
      "stylers": {
        "visibility": "off"
      }
    }
  ]
}
